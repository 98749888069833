import { useEffect, useState, useRef } from "react";
import {
  query,
  collection,
  where,
  onSnapshot,
  doc,
  collectionGroup,
  getDocs,
  updateDoc,
  addDoc,
  arrayRemove,
  setDoc,
  orderBy,
  getDoc,
  limit,
  startAfter,
} from "firebase/firestore";
import { database } from "./firebase";
import { setErrorStatus } from "../Redux/status/action";
import {
  putProceduresData,
  putProceduresHistory,
} from "../Redux/procedures/action";
import {
  addCaptureSummaryData,
  putEmployeesData,
} from "../Redux/employees/action";
import { putSensorsData } from "../Redux/sensors/action";
import { isValidArray } from "./validators";
import { putLocationsData } from "../Redux/locations/action";
import { uploadReferencePicture } from "./storage";
import { putDevices, putReferencePicture } from "../Redux/surveillance/action";
import { putComplaintsData } from "../Redux/complaints/action";
import { getFileUrl } from "./storage";

export function useLocationsListener(props) {
  const [locationsListener, setLocationsListener] = useState({
    listener: null,
  });

  const subscribeToProcedures = () => {
    const collectionRef = collection(database, "locations");
    let locations = {};
    return onSnapshot(
      collectionRef,
      (dataSnapshot) => {
        dataSnapshot.forEach((doc) => {
          locations[doc.id] = {
            ...doc.data(),
            documentId: doc.id,
          };
        });
        console.log("location called");
        putLocationsData(locations);
      },
      (error) => {
        console.error(error, "from locations listener");
        setErrorStatus(error);
      }
    );
  };
  useEffect(() => {
    if (props.isAuth === true && locationsListener.listener === null) {
      console.log("inited");
      setLocationsListener({
        listener: subscribeToProcedures(),
      });
    } else if (
      props.isAuth === false &&
      typeof locationsListener.listener === "function"
    ) {
      locationsListener.listener();
      setLocationsListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth]);
}

export function useProceduresListener(props) {
  const [proceduresListener, setProceduresListener] = useState({
    listener: null,
  });

  const subscribeToProcedures = () => {
    const collectionRef = collection(database, "procedures");

    return onSnapshot(
      collectionRef,
      (dataSnapshot) => {
        let procedures = {};
        dataSnapshot.forEach((doc) => {
          procedures[doc.id] = {
            ...doc.data(),
            documentId: doc.id,
          };
        });
        putProceduresData(procedures);
      },
      (error) => {
        console.error(error, "from procedure listener");

        setErrorStatus(error);
      }
    );
  };
  useEffect(() => {
    if (props.isAuth === true && proceduresListener.listener === null) {
      setProceduresListener({
        listener: subscribeToProcedures(),
      });
    } else if (
      props.isAuth === false &&
      typeof proceduresListener.listener === "function"
    ) {
      proceduresListener.listener();
      setProceduresListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth]);
}

export function useCaptureSummaryListener(props) {
  const [captureSummaryListener, setCaptureSummaryListener] = useState({
    listener: null,
  });

  const subscribeToCaptureSummary = () => {
    const collectionRef = collectionGroup(database, "captureSummary");

    return onSnapshot(
      collectionRef,
      (dataSnapshot) => {
        let captureSummary = {};
        dataSnapshot.forEach((doc) => {
          captureSummary[doc.data()?.location?.id] = {
            ...doc.data(),
            documentId: doc.id,
          };
        });
        addCaptureSummaryData(captureSummary);
      },
      (error) => {
        console.error(error, "from capture summary listener");

        setErrorStatus(error);
      }
    );
  };
  useEffect(() => {
    if (props.isAuth === true && captureSummaryListener.listener === null) {
      setCaptureSummaryListener({
        listener: subscribeToCaptureSummary(),
      });
    } else if (
      props.isAuth === false &&
      typeof captureSummaryListener.listener === "function"
    ) {
      captureSummaryListener.listener();
      setCaptureSummaryListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth]);
}

export function useSensorsListener(props) {
  const [sensorsListener, setSensorsListener] = useState({
    listener: null,
  });

  const subscribeToSensors = () => {
    const collectionRef = collection(database, "sensors");

    return onSnapshot(
      collectionRef,
      (dataSnapshot) => {
        let sensors = {};
        dataSnapshot.forEach((doc) => {
          sensors[doc.id] = {
            ...doc.data(),
            documentId: doc.id,
          };
        });
        putSensorsData(sensors);
      },
      (error) => {
        console.error(error, "from sensors listener");

        setErrorStatus(error);
      }
    );
  };
  useEffect(() => {
    if (props.isAuth === true && sensorsListener.listener === null) {
      setSensorsListener({
        listener: subscribeToSensors(),
      });
    } else if (
      props.isAuth === false &&
      typeof sensorsListener.listener === "function"
    ) {
      sensorsListener.listener();
      setSensorsListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth]);
}

export function useEmployeesListener(props) {
  const [employeesListener, setEmployeesListener] = useState({
    listener: null,
  });

  const subscribeToEmployees = () => {
    const collectionRef = collection(database, "employees");

    return onSnapshot(
      collectionRef,
      (dataSnapshot) => {
        let employees = {};
        dataSnapshot.forEach((doc) => {
          employees[doc.id] = doc.data();
        });

        putEmployeesData(employees);
      },
      (error) => {
        console.error(error, "from employee listener");
        setErrorStatus(error);
      }
    );
  };

  useEffect(() => {
    if (props.isAuth === true && employeesListener.listener === null) {
      setEmployeesListener({
        listener: subscribeToEmployees(),
      });
    } else if (
      props.isAuth === false &&
      typeof employeesListener.listener === "function"
    ) {
      employeesListener.listener();
      setEmployeesListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth]);
}

export function usePicturesListener(props) {
  const [picturesListener, setPicturesListener] = useState({
    listener: null,
  });

  const subscribeToPictures = () => {
    const collectionRef = collection(database, "pictures");

    return onSnapshot(
      collectionRef,
      (dataSnapshot) => {
        let pictures = {};
        dataSnapshot.forEach((doc) => {
          pictures[doc.id] = {
            ...doc.data(),
            documentId: doc.id,
          };
        });
        putReferencePicture(pictures);
      },
      (error) => {
        console.error(error, "from pictures listener");

        setErrorStatus(error);
      }
    );
  };
  useEffect(() => {
    if (
      props.isAuth === true &&
      picturesListener.listener === null &&
      props.isAllowedToRead
    ) {
      setPicturesListener({
        listener: subscribeToPictures(),
      });
    } else if (
      props.isAuth === false &&
      typeof picturesListener.listener === "function"
    ) {
      picturesListener.listener();
      setPicturesListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.isAllowedToRead]);
}

export function useDevicesListener(props) {
  const [devicesListener, setDevicesListener] = useState({
    listener: null,
  });

  const subscribeToDevices = () => {
    const collectionRef = collection(database, "devices");

    return onSnapshot(
      collectionRef,
      (dataSnapshot) => {
        let devices = {};
        dataSnapshot.forEach((doc) => {
          devices[doc.id] = {
            ...doc.data(),
            documentId: doc.id,
          };
        });
        putDevices(devices);
      },
      (error) => {
        console.error(error, "from devices listener");

        setErrorStatus(error);
      }
    );
  };
  useEffect(() => {
    if (
      props.isAuth === true &&
      devicesListener.listener === null &&
      props.isAllowedToRead
    ) {
      setDevicesListener({
        listener: subscribeToDevices(),
      });
    } else if (
      props.isAuth === false &&
      typeof devicesListener.listener === "function"
    ) {
      devicesListener.listener();
      setDevicesListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.isAllowedToRead]);
}

export function useComplaintsListener(isAuth, filterQuery) {
  const [complaintsListener, setComplaintsListener] = useState({
    listener: null,
  });
  const [complaintsLoading, setComplaintsLoading] = useState({
    assets: false,
    pagination: false,
  });
  const currentCursor = useRef(null);
  const data = useRef(null);
  const listeningFilterData = useRef(null);
  const dataLimit = 50;

  async function getComplaintsAssets(documentId) {
    try {
      setComplaintsLoading({ ...complaintsLoading, assets: true });
      if (
        !data.current[documentId]?.fileSrc ||
        !data.current[documentId]?.closure
      ) {
        const selectedData = data.current?.[documentId];
        let complaintFileSrc = {};
        let complaintImages = [];

        if (isValidArray(selectedData.assets)) {
          for (let j = 0; j < selectedData?.assets.length; j++) {
            const file = await getFileUrl(selectedData?.assets[j]);

            if (file.type === "image") {
              complaintImages.push(file.url);
            } else if (file.type === "audio") {
              complaintFileSrc = { ...complaintFileSrc, audio: file.url };
            }
          }
        }

        if (selectedData.updates) {
          for (let i = 0; i < selectedData?.updates.length; i++) {
            if (!selectedData.updates[i].assets) {
              selectedData.updates[i].assets = { images: [], audio: null };
            } else {
              if (!selectedData.updates[i].assets.images) {
                selectedData.updates[i].assets.images = [];
              }
              if (!selectedData.updates[i].assets.audio) {
                selectedData.updates[i].assets.audio = null;
              }
            }
            for (let j = 0; j < selectedData?.updates[i]?.proof?.length; j++) {
              const file = await getFileUrl(selectedData?.updates[i].proof[j]);
              if (file.type === "image") {
                selectedData.updates[i].assets.images.push(file.url);
              } else if (file.type === "audio") {
                selectedData.updates[i].assets.audio = file.url;
              }
            }
          }
        }

        let closureFileSrc = {};
        let closureImages = [];
        if (isValidArray(selectedData.closure?.proof)) {
          for (let j = 0; j < selectedData.closure?.proof.length; j++) {
            const file = await getFileUrl(selectedData.closure?.proof[j]);
            if (file.type === "image") {
              closureImages.push(file.url);
            } else if (file.type === "audio") {
              closureFileSrc = { ...closureFileSrc, audio: file.url };
            }
          }
        }

        data.current = {
          ...data.current,
          [documentId]: {
            ...selectedData,
            fileSrc: {
              ...complaintFileSrc,
              ...(isValidArray(complaintImages)
                ? { images: complaintImages }
                : {}),
            },
            closure: {
              ...selectedData.closure,
              fileSrc: {
                ...closureFileSrc,
                ...(isValidArray(closureImages)
                  ? { images: closureImages }
                  : {}),
              },
            },
          },
        };
      }
      putComplaintsData(data.current, "SET");
      setComplaintsLoading({ ...complaintsLoading, assets: false });
    } catch (error) {
      setComplaintsLoading({ ...complaintsLoading, assets: false });

      console.error("getComplaintsAssets", error);
    }
  }

  // pagination
  const getNextPage = async (preLoadedLimit, latestComplaints) => {
    setComplaintsLoading({ ...complaintsLoading, pagination: true });
    if (!currentCursor?.current) {
      return setComplaintsLoading({ ...complaintsLoading, pagination: false });
    }

    // OrderBy doesn't work when an alternative key is used with inequality operator
    let paginationQueryRef =
      Array.isArray(listeningFilterData.current) &&
      listeningFilterData.current.some((operator) => {
        return (
          operator === "<" ||
          operator === "<=" ||
          operator === ">" ||
          operator === ">=" ||
          operator === "not-in" ||
          operator === "!="
        );
      })
        ? query(
            collection(database, "complaints"),
            orderBy("createdAt", "desc"),
            startAfter(currentCursor?.current),
            limit(preLoadedLimit !== undefined ? preLoadedLimit : dataLimit)
          )
        : query(
            collection(database, "complaints"),
            orderBy("status.updatedAt", "desc"),
            startAfter(currentCursor?.current),
            limit(preLoadedLimit !== undefined ? preLoadedLimit : dataLimit)
          );

    listeningFilterData.current?.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        paginationQueryRef = query(
          paginationQueryRef,
          where(
            listeningFilterData.current[index - 2],
            listeningFilterData.current[index - 1],
            value
          )
        );
      }
    });

    const querySnapshot = await getDocs(paginationQueryRef);
    let complaints = {};
    querySnapshot.forEach((doc) => {
      complaints[doc.id] = {
        ...doc.data(),
        documentId: doc.id,
      };
    });

    if (latestComplaints) {
      data.current = {
        ...latestComplaints,
        ...complaints,
      };
    } else {
      data.current = {
        ...data.current,
        ...complaints,
      };
    }

    currentCursor.current = querySnapshot.docs[querySnapshot.docs.length - 1];
    setComplaintsLoading({ ...complaintsLoading, pagination: false });
    putComplaintsData(data.current, "SET");
  };

  // Listener
  const subscribeToComplaints = () => {
    // OrderBy doesn't work when an alternative key is used with inequality operator
    let queryRef =
      Array.isArray(filterQuery) &&
      filterQuery.some((operator) => {
        return (
          operator === "<" ||
          operator === "<=" ||
          operator === ">" ||
          operator === ">=" ||
          operator === "not-in" ||
          operator === "!="
        );
      })
        ? query(
            collection(database, "complaints"),
            orderBy("createdAt", "desc"),
            limit(dataLimit)
          )
        : query(
            collection(database, "complaints"),
            orderBy("status.updatedAt", "desc"),
            limit(dataLimit)
          );
    filterQuery.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        queryRef = query(
          queryRef,
          where(filterQuery[index - 2], filterQuery[index - 1], value)
        );
      }
    });

    return onSnapshot(
      queryRef,
      (dataSnapshot) => {
        let complaints = {};
        dataSnapshot.forEach((doc) => {
          complaints[doc.id] = { ...doc.data(), documentId: doc.id };
        });

        currentCursor.current = dataSnapshot.docs[dataSnapshot.docs.length - 1];
        // Check if filters are changed from previously running listener
        if (
          data.current === null ||
          JSON.stringify(listeningFilterData.current) !==
            JSON.stringify(filterQuery)
        ) {
          data.current = complaints;
          listeningFilterData.current = filterQuery;
          putComplaintsData(data.current, "SET");
        } else if (Object.values(data.current).length > dataLimit) {
          const previousComplaintsTotal =
            Object.values(data.current).length - dataLimit;

          getNextPage(previousComplaintsTotal, complaints);
        } else {
          data.current = complaints;
          putComplaintsData(data.current, "SET");
        }
      },
      (error) => {
        console.error(error, "from complaints listener");
        setErrorStatus(error);
      }
    );
  };

  function getComplaintsNextPage() {
    getNextPage();
  }

  useEffect(() => {
    if (
      typeof complaintsListener.listener === "function" &&
      isAuth === true &&
      filterQuery
    ) {
      complaintsListener.listener();
      setComplaintsListener({
        listener: subscribeToComplaints(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery]);

  useEffect(() => {
    if (isAuth === true && complaintsListener.listener === null) {
      setComplaintsListener({
        listener: subscribeToComplaints(),
      });
    } else if (
      typeof complaintsListener.listener === "function" &&
      isAuth === false
    ) {
      complaintsListener.listener();
      setComplaintsListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [isAuth, complaintsListener]);

  return [getComplaintsNextPage, getComplaintsAssets, complaintsLoading];
}

export function useProcedureHistoryListener(isAuth, filterQuery) {
  const [procedureHistoryListener, setProcedureHistoryListener] = useState({
    listener: null,
  });
  const [proceduresAssetsLoading, setProceduresAssetsLoading] = useState(false);
  const currentCursor = useRef(null);
  const data = useRef(null);
  const listeningFilterData = useRef(null);
  const dataLimit = 50;

  async function getProcedureHistoryAssets(documentId) {
    const storeData = data.current[documentId];

    try {
      setProceduresAssetsLoading(true);
      const procedureUpdates = storeData.latestUpdate;

      for (let i = 0; i < procedureUpdates.length; i++) {
        const assets = procedureUpdates[i].proof;
        let images = [];
        let fileSrc = {};
        if (isValidArray(assets)) {
          for (let k = 0; k < assets.length; k++) {
            const file = await getFileUrl(assets[k]);
            if (file.type === "image") {
              images.push(file.url);
            } else if (file.type === "audio") {
              fileSrc = { ...fileSrc, audio: file.url };
            }
          }

          procedureUpdates[i] = {
            ...procedureUpdates[i],
            fileSrc: {
              ...fileSrc,
              ...(isValidArray(images) ? { images: images } : {}),
            },
          };
        } else {
          procedureUpdates[i] = {
            ...procedureUpdates[i],
            fileSrc: {},
          };
        }
        data.current = {
          ...data.current,
          [documentId]: {
            ...storeData,
          },
        };
        putProceduresHistory(data.current);
      }
      setProceduresAssetsLoading(false);
    } catch (error) {
      setProceduresAssetsLoading(false);
      console.error("getProcedureHistoryAssets", error);
    }
  }

  // pagination
  const getNextPage = async (preLoadedLimit, latestProcedureHistory) => {
    if (!currentCursor?.current) {
      return;
    }
    let paginationQueryRef = query(
      collectionGroup(database, "procedureHistory"),
      orderBy("createdAt", "desc"),
      startAfter(currentCursor?.current),
      limit(preLoadedLimit || dataLimit)
    );

    listeningFilterData.current?.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        paginationQueryRef = query(
          paginationQueryRef,
          where(
            listeningFilterData.current[index - 2],
            listeningFilterData.current[index - 1],
            value
          )
        );
      }
    });

    const querySnapshot = await getDocs(paginationQueryRef);
    let procedureHistory = {};
    querySnapshot.forEach((doc) => {
      procedureHistory[doc.id] = {
        ...doc.data(),
        documentId: doc.id,
      };
    });

    if (latestProcedureHistory) {
      data.current = {
        ...latestProcedureHistory,
        ...procedureHistory,
      };
    } else {
      data.current = {
        ...data.current,
        ...procedureHistory,
      };
    }
    currentCursor.current = querySnapshot.docs[querySnapshot.docs.length - 1];
    putProceduresHistory(data.current);
  };

  // Listener
  const subscribeToProcedureHistory = () => {
    // If "in" values multiplied with each other is equal to or grater than 20, stop.
    let queryRef = query(
      collectionGroup(database, "procedureHistory"),
      orderBy("createdAt", "desc"),
      limit(dataLimit)
    );

    filterQuery?.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        queryRef = query(
          queryRef,
          where(filterQuery[index - 2], filterQuery[index - 1], value)
        );
      }
    });

    return onSnapshot(
      queryRef,
      (dataSnapshot) => {
        let procedureHistory = {};
        dataSnapshot.forEach((doc) => {
          procedureHistory[doc.id] = { ...doc.data(), documentId: doc.id };
        });

        currentCursor.current = dataSnapshot.docs[dataSnapshot.docs.length - 1];
        // Check if filters are changed from previously running listener
        if (
          data.current === null ||
          JSON.stringify(listeningFilterData.current) !==
            JSON.stringify(filterQuery)
        ) {
          data.current = procedureHistory;
          listeningFilterData.current = filterQuery;
          putProceduresHistory(data.current);
        } else if (Object.values(data.current).length > dataLimit) {
          const previousProcedureHistoryTotal =
            Object.values(data.current).length - dataLimit;

          data.current = procedureHistory;
          getNextPage(previousProcedureHistoryTotal, procedureHistory);
        } else {
          data.current = procedureHistory;
          putProceduresHistory(data.current);
        }
      },
      (error) => {
        console.error(error, "from complaints listener");
        setErrorStatus(error);
      }
    );
  };

  function getProcedureHistoryNextPage() {
    getNextPage();
  }

  useEffect(() => {
    if (
      typeof procedureHistoryListener.listener === "function" &&
      isAuth === true &&
      filterQuery
    ) {
      procedureHistoryListener.listener();
      setProcedureHistoryListener({
        listener: subscribeToProcedureHistory(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery]);

  useEffect(() => {
    if (isAuth === true && procedureHistoryListener.listener === null) {
      setProcedureHistoryListener({
        listener: subscribeToProcedureHistory(),
      });
    } else if (
      typeof procedureHistoryListener.listener === "function" &&
      isAuth === false
    ) {
      procedureHistoryListener.listener();
      setProcedureHistoryListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [isAuth, procedureHistoryListener]);

  return [
    getProcedureHistoryNextPage,
    getProcedureHistoryAssets,
    proceduresAssetsLoading,
  ];
}

export async function getComplaint(complaintId) {
  const docRef = doc(database, "complaints", complaintId);
  const docSnapshot = await getDoc(docRef);
  return { ...docSnapshot.data(), documentId: docSnapshot.id };
}

export async function getComplaintSummary() {
  const querySnapshot = await getDocs(
    collectionGroup(database, "complaintSummary")
  );

  const complaints = [];
  querySnapshot.forEach((doc) => {
    complaints.push({ documentId: doc.id, ...doc.data() });
  });

  return complaints;
}

export async function updateVerifyCompliant(complaintId, data) {
  await updateDoc(doc(database, "complaints", complaintId), data);
}

export async function getProcedureHistory(locationId) {
  const procedureHistoryQuery = query(
    collectionGroup(database, "procedureHistory"),
    where("locationId", "==", locationId)
  );
  const querySnapshot = await getDocs(procedureHistoryQuery);
  const procedureHistory = [];
  querySnapshot.forEach((doc) => {
    procedureHistory.push({ documentId: doc.id, ...doc.data() });
  });
  return procedureHistory;
}

export const createProcedurePreset = async (data) => {
  try {
    await addDoc(collection(database, "procedures"), { ...data });
  } catch (error) {
    console.error("createProcedurePreset Error:", error);
    throw error;
  }
};

export const editProcedurePreset = async (documentId, data) => {
  await updateDoc(doc(database, "procedures", documentId), data);
};

export const assignProcedureToEmployee = async (documentId, data) => {
  await updateDoc(doc(database, "employees", documentId), {
    linkedProcedures: data,
  });
};

export const assignProcedureToSensor = async (documentId, data) => {
  await updateDoc(doc(database, "sensors", documentId), {
    linkedProcedures: data,
  });
};

export const createNewEmployee = async (data) => {
  const { id: documentId } = await addDoc(collection(database, "employees"), {
    ...data,
  });
  return documentId;
};

export const updateEmployeeId = async (documentId) => {
  await updateDoc(doc(database, "employees", documentId), {
    employeeId: documentId,
  });
};

export const createNewSensor = async (data) => {
  await addDoc(collection(database, "sensors"), { name: data });
};

export const deleteLinkedProcedures = async (documentId, data) => {
  await updateDoc(doc(database, "employees", documentId), {
    linkedProcedures: isValidArray(data)
      ? arrayRemove(...data)
      : arrayRemove(data),
  });
};

export async function updateCompliant(compliantId, employeeId, proof) {
  const rootRef = doc(database, "complaints", compliantId);

  await updateDoc(rootRef, {
    status: { currentStatus: "UNDER REVIEW", updatedAt: +new Date() },
    closure: {
      resolvedAt: +new Date(),
      resolvedBy: employeeId,
      proof: proof,
    },
  });
}

export const deleteLinkedSensorProcedures = async (documentId, data) => {
  await updateDoc(doc(database, "sensors", documentId), {
    linkedProcedures: arrayRemove(data),
  });
};

export async function takeoverCompliant(compliantId, employeeData) {
  const rootRef = doc(database, "complaints", compliantId);

  await updateDoc(rootRef, {
    takenOverBy: employeeData,
  });
}

export async function createNewPicture(data, asset) {
  const pictureRef = doc(collection(database, "pictures"));
  const assetPath = await uploadReferencePicture(asset, `${pictureRef.id}.jpg`);
  const date = +new Date();
  await setDoc(pictureRef, {
    ...data,
    asset: assetPath,
    updatedAt: date,
    lastAppliedAt: date,
  });
}

export async function editPicture(documentId) {
  await updateDoc(doc(database, "pictures", documentId), {
    action: "update",
  });
}

export async function reloadPictureUpload(documentId) {
  await updateDoc(doc(database, "pictures", documentId), {
    lastAppliedAt: +new Date(),
  });
}

export async function createNewDevice(data) {
  const devicesRef = collection(database, "devices");
  await addDoc(devicesRef, data);
}

export async function getAllTracks(phoneNumber, timestamp) {
  const morning = +new Date(timestamp).setHours(0, 0, 0, 0);
  const night = +new Date(timestamp).setHours(0, 0, 0, 0) + 86400000;
  const queriesRef = query(
    collection(database, "tracks"),
    where("phoneNumber", "==", phoneNumber),
    where("timestamp", ">=", morning),
    where("timestamp", "<=", night),
    orderBy("timestamp", "asc")
  );
  //+918525957550
  // +919025013771
  // +918939263127;
  const queriesSnapshot = await getDocs(queriesRef);
  let queries = [];
  queriesSnapshot.forEach((query) => {
    queries.push(query.data());
  });
  return queries;
}

export async function getEmployeeData(phoneNumber) {
  const employeeRef = query(
    collection(database, "employees"),
    where("phoneNumber", "==", phoneNumber)
  );
  let employee = {};
  const employeeSnapshot = await getDocs(employeeRef);
  employeeSnapshot.forEach((doc) => {
    employee = {
      ...doc.data(),
      documentId: doc.id,
    };
  });
  return employee;
}

export async function getRolesData() {
  const rolesRef = query(collection(database, "roles"));
  let roles = {};
  const rolesSnapshot = await getDocs(rolesRef);
  rolesSnapshot.forEach((doc) => {
    roles[doc.id] = {
      ...doc.data(),
      documentId: doc.id,
    };
  });
  return roles;
}

export async function addRolesToEmployee(documentId, data) {
  await updateDoc(doc(database, "employees", documentId), {
    roles: data,
  });
}

export async function getPictureStatusData(pictureId) {
  const statusRef = query(
    collectionGroup(database, "status"),
    where("pictureId", "==", pictureId)
  );
  let status = {};
  const employeeSnapshot = await getDocs(statusRef);
  employeeSnapshot.forEach((doc) => {
    status[doc.id] = { ...doc.data(), documentId: doc.id };
  });
  return status;
}

export async function getAllComplaints() {
  const complaintsRef = query(collection(database, "complaints"));
  const complaintsSnapshot = await getDocs(complaintsRef);
  return complaintsSnapshot.docs.map((doc) => doc.data());
}

export async function getEmployeeCaptures(employeeId) {
  const capturesRef = query(
    collectionGroup(database, "capture"),
    where("employee.id", "==", employeeId)
  );

  const capturesSnapshot = await getDocs(capturesRef);

  const captures = capturesSnapshot.docs.reduce((acc, doc) => {
    acc[doc.id] = { ...doc.data(), documentId: doc.id };
    return acc;
  }, {});

  return captures;
}

// to find why listener trigged

// dataSnapshot.docChanges().forEach((change) => {
//   const doc = change.doc;
//   if (change.type === "added") {
//     console.log(`Document added: ${doc.id}`, doc.data());
//   }
//   if (change.type === "modified") {
//     console.log(`Document modified: ${doc.id}`, doc.data());
//   }
//   if (change.type === "removed") {
//     console.log(`Document removed: ${doc.id}`);
//   }
// });
